<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .section-1 {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    gap: 12px;
    margin-top: 12px;
    .section-1-item {
      flex: 1;
      flex-shrink: 0;
    }
  }

  .section-3 {
    margin-top: 12px;
  }
}
</style>

<template>
  <div class="layout">
    <data-single style="margin-top: 12px" :list="data5"></data-single>
    <progress-section style="margin-top: 12px" :list="data6"></progress-section>
    <div class="section-1">
      <div class="section-1-item">
        <box-item title="本年消耗(kWh)">
          <line-chart style="height: 200px" :list="data1"></line-chart>
        </box-item>
      </div>

      <div class="section-1-item">
        <box-item title="本年成本(元)">
          <line-chart style="height: 200px" :list="data2"></line-chart>
        </box-item>
      </div>

      <div class="section-1-item">
        <box-item title="去年消耗(kWh)">
          <line-chart style="height: 200px" :list="data3"></line-chart>
        </box-item>
      </div>

      <div class="section-1-item">
        <box-item title="去年成本(元)">
          <line-chart style="height: 200px" :list="data4"></line-chart>
        </box-item>
      </div>
    </div>
    <div class="section-3">
      <box-item title="子空间消耗(kWh)" >
             <bar-chart style="height: 250px" :list="data7"></bar-chart>
      </box-item>
    </div>
  </div>
</template>


<script setup>
import { ref } from "vue";
import moment from "moment";
import { RandomNumber } from "@/utils";
const data1 = ref([]);
const data2 = ref([]);
const data3 = ref([]);
const data4 = ref([]);
const data5 = ref([
  {
    name: "本年消耗 吨标准煤 (TCE)",
    value: 0,
  },
  {
    name: "本年消耗 吨二氧化碳排放 (TCO2E)",
    value: 0,
  },
  {
    name: "标准煤单位面积值 (TCE/M²)",
    value: 0,
  },
  {
    name: "二氧化碳单位面积值 (TCO2E/M²)",
    value: 0,
  },
]);

const data6 = ref([
  {
    name: "分时消耗电量",
    value: 0,
    df:true
  },
  {
    name: "成本占比",
    value: 0,
  },
  {
    name:"吨标准煤(TCE)占比",
    value:0
  },
  {
    name: "吨二氧化碳排放(TCO2E)占比",
    value: 0,
    df:true
  },
]);

const data7=ref([
    {
        name:"区域1",
        value:RandomNumber(2000,2)
    },
      {
        name:"区域2",
        value:RandomNumber(2000,2)
    },  {
        name:"区域3",
        value:RandomNumber(2000,2)
    },  {
        name:"区域4",
        value:RandomNumber(2000,2)
    },
      {
        name:"区域5",
        value:RandomNumber(2000,2)
    },
      {
        name:"区域6",
        value:RandomNumber(2000,2)
    },  {
        name:"区域7",
        value:RandomNumber(2000,2)
    },  {
        name:"区域8",
        value:RandomNumber(2000,2)
    },
     {
        name:"区域9",
        value:RandomNumber(2000,2)
    }, {
        name:"区域10    ",
        value:RandomNumber(2000,2)
    }
])
const loadData1 = () => {
  let array = [];
  for (let index = 0; index < new Date().getMonth() + 1; index++) {
    array.push({
      name: index + 1 + "月",
      value: 2540 + RandomNumber(2450, 2),
    });
  }
  data1.value = array;
};

const loadData2 = () => {
  let array = [];

  for (let index = 0; index < new Date().getMonth() + 1; index++) {
    array.push({
      name: index + 1 + "月",
      value: 1222 + RandomNumber(1255, 2),
    });
  }
  data2.value = array;
};

const loadData3 = () => {
  let array = [];
  for (let index = 0; index < 12; index++) {
    array.push({
      name: index + 1 + "月",
      value: 2540 + RandomNumber(2450, 2),
    });
  }
  data3.value = array;
};

const loadData4 = () => {
  let array = [];
  for (let index = 0; index < 12; index++) {
    array.push({
      name: index + 1 + "月",
      value: 1220 + RandomNumber(1220, 2),
    });
  }
  data4.value = array;
};

const loadData5 = () => {
     let data = data5.value;
  data[0].value = RandomNumber(1021, 2);
  data[1].value = RandomNumber(10020, 2);
  data[2].value = RandomNumber(1202, 2);
  data[3].value = RandomNumber(13000, 2);
};

const loadData6=()=>{
     let data = data6.value;
  data[0].value = RandomNumber(10110, 2);
  data[1].value = RandomNumber(100, 2);
  data[2].value = RandomNumber(100, 2);
  data[3].value = RandomNumber(100, 2);
}

const loadData = () => {
  loadData1();
  loadData2();
  loadData3();
  loadData4();
  loadData5();
  loadData6()
};

loadData();
</script>